// .MuiTableCell-root {
// 	border-bottom: 1px solid !important;
// }

.client-details .MuiAccordion-root.Mui-expanded {
	margin: 0;
}

.client-details .MuiAccordion-rounded {
	border-radius: 4px;
	border-color: #cecece;
}

.client-details .restore {
	border: 2px solid;
	border-radius: 100%;
	height: 35px;
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #307ad6;
}
.restore svg {
	transform: rotate(35deg);
}

/* .client-details .MuiTableCell-root {
	white-space: nowrap;
} */

.client-details .note-field {
	min-width: 240px;
}

.companyDetail {
	width: 100% !important;
}

@media (max-width: 1280px) {
	.client-details .MuiGrid-grid-md-6 {
		flex-grow: 0;
		max-width: 100%;
		flex-basis: 100%;
	}
}

@media (min-width: 1280px) {
	.client-details .MuiGrid-grid-lg-6 {
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
}

@media (max-width: 414px) {
	.companyDetailGrid {
		display: flex;
		flex-direction: column;
	}

	.client-details {
		margin-bottom: 45px;
		margin-top: 55px;
	}
}
