.arrow {
	display: flex;
	flex-direction: column;
	width: 40px;
}

.arrowUp {
	transform: rotate(180deg);
	cursor: pointer;
}

.arrowDown {
	cursor: pointer;
}
