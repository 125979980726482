.max-two-line {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-all;
}

.white-space-nowrap {
	white-space: nowrap;
	word-break: break-all;
}
