@media only screen and (max-width: 414px) {
	.makeStyles-dialogContent-31 {
		width: auto;
	}

	.clientListContentContainer {
		margin-bottom: 50px;
		margin-top: 55px;
	}
}
