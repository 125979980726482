.center-text {
	text-align: center !important;
}

.mb-z {
	margin-bottom: 0 !important;
}
.mt-z {
	margin-top: 0 !important;
}
.mr-z {
	margin-right: 0 !important;
}
.ml-z {
	margin-left: 0 !important;
}

.mb-xsm {
	margin-bottom: 0.5rem !important;
}
.mb-sm {
	margin-bottom: 0.75rem !important;
}
.mb-md {
	margin-bottom: 1.5rem !important;
}
.mb-bg {
	margin-bottom: 2.75rem !important;
}
.mb-hg {
	margin-bottom: 6rem !important;
}

.mt-xsm {
	margin-top: 0.5rem !important;
}
.mt-sm {
	margin-top: 0.75rem !important;
}
.mt-md {
	margin-top: 1.5rem !important;
}
.mt-bg {
	margin-top: 2.75rem !important;
}
.mt-hg {
	margin-top: 6rem !important;
}

.ml-xsm {
	margin-left: 0.5rem !important;
}
.ml-sm {
	margin-left: 0.75rem !important;
}
.ml-md {
	margin-left: 1.5rem !important;
}
.ml-bg {
	margin-left: 2.75rem !important;
}
.ml-hg {
	margin-left: 6rem !important;
}

.mr-xsm {
	margin-right: 0.5rem !important;
}
.mr-sm {
	margin-right: 0.75rem !important;
}
.mr-md {
	margin-right: 1.5rem !important;
}
.mr-bg {
	margin-right: 2.75rem !important;
}
.mr-hg {
	margin-right: 6rem !important;
}

.pb-xsm {
	padding-bottom: 0.5rem !important;
}
.pb-sm {
	padding-bottom: 0.75rem !important;
}
.pb-md {
	padding-bottom: 1.5rem !important;
}
.pb-bg {
	padding-bottom: 2.75rem !important;
}
.pb-hg {
	padding-bottom: 6rem !important;
}

.pt-xsm {
	padding-top: 0.5rem !important;
}
.pt-sm {
	padding-top: 0.75rem !important;
}
.pt-md {
	padding-top: 1.5rem !important;
}
.pt-bg {
	padding-top: 2.75rem !important;
}
.pt-hg {
	padding-top: 6rem !important;
}

.pl-xsm {
	padding-left: 0.5rem !important;
}
.pl-sm {
	padding-left: 0.75rem !important;
}
.pl-md {
	padding-left: 1.5rem !important;
}
.pl-bg {
	padding-left: 2.75rem !important;
}
.pl-hg {
	padding-left: 6rem !important;
}

.pr-xsm {
	padding-right: 0.5rem !important;
}
.pr-sm {
	padding-right: 0.75rem !important;
}
.pr-md {
	padding-right: 1.5rem !important;
}
.pr-bg {
	padding-right: 2.75rem !important;
}
.pr-hg {
	padding-right: 6rem !important;
}

.wd-10 {
	width: 10% !important;
}
.wd-20 {
	width: 20% !important;
}
.wd-30 {
	width: 30% !important;
}
.wd-40 {
	width: 40% !important;
}
.wd-50 {
	width: 50% !important;
}
.wd-60 {
	width: 60% !important;
}
.wd-70 {
	width: 70% !important;
}
.wd-80 {
	width: 80% !important;
}
.wd-90 {
	width: 90% !important;
}
.wd-100 {
	width: 100% !important;
}

.bold-500 {
	font-weight: 500;
}
.bold-600 {
	font-weight: 600;
}
.bold-700 {
	font-weight: 700;
}
.bold-800 {
	font-weight: 800;
}
.bold-900 {
	font-weight: 900;
}
.bold {
	font-weight: bold;
}

.text-bold {
	font-weight: 600;
}

.text-sm {
	font-size: 12px;
}
.text-md {
	font-size: 14px;
}
.text-bg {
	font-size: 17px;
}

.align-right {
	text-align: right;
}
.align-left {
	text-align: left;
}
.align-center {
	text-align: center;
}

.space-between {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}
.flex {
	display: flex;
	align-items: center;
	flex-direction: row;
}
.flex-column {
	display: flex;
	flex-direction: column;
}

.grid {
	display: grid;
	&-half {
		grid-template-columns: 50% 50%;
		grid-gap: 10px;
	}
}

.right {
	float: right;
}
.left {
	float: left;
}
.display-block {
	display: block;
}
.display-visit {
	display: block;
	height: 0;
	width: 0;
}

.items-center {
	align-items: center !important;
}
.items-start {
	align-items: flex-start !important;
}
.items-end {
	align-items: flex-start !important;
}

.justify-center {
	justify-content: center !important;
}
.justify-end {
	justify-content: flex-end !important;
}
.justify-start {
	justify-content: flex-start !important;
}
.justify-between {
	justify-content: space-between !important;
}
.justify-around {
	justify-content: space-around !important;
}
.cursor-pointer {
	cursor: pointer;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.white-space-nowrap {
	white-space: nowrap;
	word-break: break-all;
}

.text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
}

.max-two-line {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	overflow-wrap: break-word;
}

.new-link {
	text-decoration: underline !important;
	color: #307ad7 !important;
	cursor: pointer;
}

.link-color {
	color: #2b89ea;
}

.black-filter {
	filter: brightness(0) invert(0);
}

.white-filter {
	filter: brightness(0) invert(1);
}
.model-dialog-padding {
	padding: 10px;
}

.sl-white-border {
	background: #fafafa;
	height: 29px;
	width: 4px;
	position: absolute;
	left: -2px;
	top: 14px;
	&.last-child-row {
		left: -36px;
		top: 0;
		height: 100%;
	}
}

.link:hover {
	text-decoration: underline !important;
	color: #307ad7 !important;
	cursor: pointer;
}

.position-relative {
	position: relative;
}

.caption-label {
	display: flex;
	align-items: center;
	gap: 10px !important;
}
