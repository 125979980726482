.mobileTableViewEdit {
	display: none;
}

@media only screen and (max-width: 414px) {
	.desktopTableViewEdit {
		display: none;
	}

	.mobileTableViewEdit {
		display: block;
	}
}
