.audio-player {
	height: 70px;
	border: 1px solid #d2d2d9;
	border-radius: 5px;
	font-family: arial;
	color: white;
	font-size: 0.75em;
	// overflow: hidden;
	display: flex;
	align-items: center;
}

.audio-player .timeline {
	width: 100%;
	position: relative;
	cursor: pointer;
	flex-direction: column;
	align-items: flex-start !important;
	padding-left: 20px;
	margin-top: 20px;
}
.audio-player .play-container {
	background: #24ba78;
	border-radius: 50%;
	min-width: 40px;
	height: 40px;
	margin-top: 4px;
}
.audio-player .timeline .progress-container {
	position: relative;
	width: 100%;
	height: 10px;
}

.audio-player .timeline .progress {
	background: #307ad6;
	width: 0%;
	height: 6px;
	transition: 0.25s;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 8px;
}
.audio-player .timeline .progress-background {
	width: 100%;
	background: #d2d2d9;
	height: 6px;
	border-radius: 8px;
}
.audio-player .controls {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	padding: 0 20px;
	width: 100%;
}
.audio-player .controls > * {
	display: flex;
	justify-content: center;
	align-items: center;
}
.audio-player .controls .toggle-play.play {
	cursor: pointer;
	position: relative;
	left: 5px;
	height: 0;
	width: 0;
	border: 7px solid #0000;
	border-left: 13px solid white;
}
.audio-player .controls .toggle-play.play:hover {
	transform: scale(1.1);
}
.audio-player .controls .toggle-play.pause {
	height: 15px;
	width: 20px;
	cursor: pointer;
	position: relative;
}
.audio-player .controls .toggle-play.pause:before {
	position: absolute;
	top: 0;
	left: 5px;
	background: white;
	content: "";
	height: 15px;
	width: 3px;
}
.audio-player .controls .toggle-play.pause:after {
	position: absolute;
	top: 0;
	right: 5px;
	background: white;
	content: "";
	height: 15px;
	width: 3px;
}
.audio-player .controls .toggle-play.pause:hover {
	transform: scale(1.1);
}
.audio-player .controls .time {
	display: flex;
	color: #404149;
}
.audio-player .controls .time > * {
	padding: 2px;
}
.audio-player .controls .volume-container {
	cursor: pointer;
	position: relative;
	z-index: 2;
	padding-left: 15px;
}
.audio-player .controls .volume-container .volume-button {
	height: 26px;
	display: flex;
	align-items: center;
}
.audio-player .controls .volume-container .volume-button .volume {
	transform: scale(0.7);
}
.volume-slider {
	transform: rotate(-90deg);
}
.audio-player .controls .volume-container .volume-slider {
	position: absolute;
	top: -25px;
	// left: -32px;

	z-index: -1;
	width: 0;
	height: 6px;
	background: #d2d2d9;
	transition: 0.25s;
	border-radius: 8px;
}
.audio-player .controls .volume-container .volume-slider .volume-percentage {
	background: #307ad6;
	height: 100%;
	width: 75%;
	border-radius: 8px;
}
.audio-player .controls .volume-container:hover .volume-slider {
	top: -40px;
	width: 80px;
}

.icono-volumeHigh:after,
.icono-volumeHigh:before,
.icono-volumeLow:before,
.icono-volumeMedium:before,
[class*="icono-"][class*="Circle"],
[class*="icono-"][class*="Square"],
[class*="icono-check"][class*="Circle"] {
	border: 2px solid;
}

.icono-volumeDecrease:after,
.icono-volumeDecrease:before,
.icono-volumeHigh:after,
.icono-volumeHigh:before,
.icono-volumeIncrease:after,
.icono-volumeIncrease:before,
.icono-volumeLow:before,
.icono-volumeMedium:before,
.icono-volumeMute:after,
.icono-volumeMute:before,
.stickCenterV {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.icono-volume,
.icono-volumeDecrease,
.icono-volumeHigh,
.icono-volumeIncrease,
.icono-volumeLow,
.icono-volumeMedium,
.icono-volumeMute {
	width: 0;
	height: 0;
	border: 7px solid;
	border-left: none;
	border-top-color: transparent;
	border-bottom-color: transparent;
	padding: 6px 3px;
	box-shadow: inset 4px 0;
	margin: 4px 10px 4px 11px;
	@media only screen and (min-width: 1100px) and (max-width: 1250px) {
		box-shadow: inset 5px 0;
	}
}
.icono-volumeHigh,
.icono-volumeLow,
.icono-volumeMedium {
	margin: 4px 14px 4px 7px;
}
.icono-volumeHigh:after,
.icono-volumeHigh:before,
.icono-volumeLow:before,
.icono-volumeMedium:before {
	width: 15px;
	height: 15px;
	position: absolute;
	border-radius: 50%;
	border-top-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	left: 2px;
}
.icono-volumeHigh,
.icono-volumeMedium {
	margin: 4px 16px 4px 5px;
}
.icono-volumeHigh:before,
.icono-volumeMedium:before {
	border-style: double;
	border-width: 6px;
	left: -2px;
}
.icono-volumeHigh {
	margin: 4px 18px 4px 3px;
}
.icono-volumeHigh:after {
	width: 32px;
	height: 32px;
	left: -7px;
}
.icono-volumeDecrease,
.icono-volumeIncrease,
.icono-volumeMute {
	margin: 4px 16px 4px 5px;
}
.icono-volumeDecrease:after,
.icono-volumeDecrease:before,
.icono-volumeIncrease:after,
.icono-volumeIncrease:before,
.icono-volumeMute:after,
.icono-volumeMute:before {
	box-shadow: inset 0 0 0 32px;
}
.icono-volumeDecrease:before,
.icono-volumeIncrease:before,
.icono-volumeMute:before {
	width: 10px;
	height: 2px;
	left: 17px;
}
.icono-volumeIncrease:after,
.icono-volumeMute:after {
	height: 10px;
	width: 2px;
	left: 21px;
}
.icono-volumeMute:after,
.icono-volumeMute:before {
	-webkit-transform: translateY(-50%) rotate(45deg);
	-ms-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
}

[class*="icono-"] {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	font-style: normal;
	color: #404149;
	text-align: left;
	text-indent: -9999px;
	direction: ltr;
}
[class*="icono-"]:after,
[class*="icono-"]:before {
	content: "";
	pointer-events: none;
	box-sizing: unset;
}
[class*="icono-"][class*="Circle"] {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: 2px;
}
[class*="icono-"][class*="Square"] {
	border-radius: 4px;
	width: 30px;
	height: 30px;
	margin: 2px;
}
[class*="icono-"],
[class*="icono-"] * {
	box-sizing: border-box;
}
