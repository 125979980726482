@media only screen and (max-width: 414px) {
	.applicationTableContainer {
		background-color: black;
		max-width: 88vw !important;
		overflow-x: scroll !important;
		/* padding-right: 20px */
		padding-right: 0px;
	}
	.addNewBtn {
		width: 100px !important;
	}

	.applicationSearchBtn {
		margin-left: 0 !important;
	}

	.MuiInput-formControl {
		width: 79vw;
	}

	.modalText {
		font-size: 10px !important;
	}
}

@media only screen and (max-width: 376px) {
	.applicationTableContainer {
		max-width: 87vw !important;
		overflow-x: scroll !important;
		padding-right: 20px;
	}
}
