.siteDetailsContainerDesktop {
	width: 100%;
}

.siteDetailsContainerMobile {
	display: none;
}

@media only screen and (max-width: 414px) {
	.siteDetailsContainerDesktop {
		display: none;
	}

	.siteDetailsContainerMobile {
		display: flex;
	}
}
