.row__questions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 13px 0;
	position: relative;
}

.row__questions__icon {
	padding: 0px 7px;
	border-right: 2px solid #cfcfcf;
	display: flex;
	height: 26px;
	align-items: center;
}

.row__questions__item {
	margin: 0 15px;
	color: #534a4a;
	flex: 1;
}

.row__questions__moreicon {
	margin-left: 8px;
	display: flex;
}

.children {
	margin-left: 38px;
}

.row__main {
	display: flex;
	align-items: center;
}

.cursor {
	cursor: pointer;
}

.highlight {
	/* background-color: rgb(48, 122, 215);
	color: white; */
	background: #ffeb3b;
	padding: 2px 7px;
	border-radius: 3px;
}

.detailContent {
	overflow-y: auto !important;
}

.taskdetail-dialog > .MuiPaper-root {
	min-width: 75% !important;
}
