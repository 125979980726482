.required {
	color: #e31212;
}

.App .MuiTableCell-root {
	padding: 16px 10px;
}

.sb-show-main {
	.nav-skeleton {
		position: absolute;
	}
}
div[role="tooltip"] {
	z-index: 10000 !important;
}
