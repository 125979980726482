.MuiBottomNavigation-root.mobileNavigation {
	display: none;
}

.miniLogoMobile {
	display: none;
}

.upperContent::-webkit-scrollbar {
	width: 2px;
	// height: 13px;
}

.upperContent::-webkit-scrollbar-thumb {
	background: linear-gradient(13deg, #444444 14%, #444444 64%);
	border-radius: 10px;
}
.upperContent::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(13deg, #c7ceff 14%, #f9d4ff 64%);
}
.upperContent::-webkit-scrollbar-track {
	background: #d98d24;
	border-radius: 10px;
	box-shadow: inset 7px 10px 12px #d98d24;
}

@media only screen and (max-width: 414px) {
	.MuiBottomNavigation-root.mobileNavigation {
		display: flex;
		background-color: #d98d24;

		// width: 100vw;
	}

	.drawerDesktop {
		display: none;
	}

	.appBar {
		width: 100vw;
		height: 55px;
		background-color: #d98d24;
		position: fixed;
		z-index: 100;
	}

	.bottomNavigation {
		width: 90vw;
		position: fixed;
		z-index: 100;
		bottom: 0;
		left: 0;
	}

	.miniLogoMobile {
		display: flex;
	}

	.MuiListItem-gutters {
		background-color: #d98d24;
		fill: white;
	}

	.mobNavListContainer {
		margin: 0 -6px;
	}

	.appBar img {
		width: 33px;
		margin-top: 12px;
		margin-left: 10px;
	}
}

@media only screen and (max-width: 376px) {
	.mobNavListContainer {
		margin: 0 -8px;
	}
}

.drawerDesktop.collapse .MuiListItem-gutters {
	padding-left: 11px;
}
