.application-dailog {
	.MuiDialog-paper,
	.MuiDialogContent-root {
		overflow-y: unset;
		padding: 10px;
	}
	.MuiLinearProgress-root {
		height: 4px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	.MuiDialogTitle-root {
		flex: 0 0 auto;
		margin: 0;
		padding: 16px 10px;
	}
}

.large-application-dailog {
	.MuiDialog-paper,
	.MuiDialogContent-root {
		//overflow-y: unset;
		max-width: 100%;
		width: 100%;
		padding: 10px;
	}
	.MuiLinearProgress-root {
		height: 4px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	.MuiDialogTitle-root {
		flex: 0 0 auto;
		margin: 0;
		padding: 16px 10px;
	}
}

.medium-application-dailog {
	.MuiDialog-paper,
	.MuiDialogContent-root {
		//overflow-y: unset;
		max-width: 700px;
		width: 100%;
		padding: 10px;
	}
	.MuiLinearProgress-root {
		height: 4px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	.MuiDialogTitle-root {
		flex: 0 0 auto;
		margin: 0;
		padding: 16px 10px;
	}
}

.MuiDialogTitle-root {
	flex: 0 0 auto;
	margin: 0;
	padding: 16px 10px;
}

.table-container {
	width: 100%;
	overflow: auto;
	/* width */
	&::-webkit-scrollbar {
		width: 14px;
		height: 14px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #bebebe;
		border-radius: 12px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.div-root-94 {
	.PrivateSwitchBase-root-114 {
		padding: 9px 9px 9px 0;
	}
}

.ql-editor {
	min-height: 85px;
}

.image_Viewer_Dialouge {
	.MuiDialog-paper,
	.MuiDialogContent-root {
		max-width: 100%;
		padding: 0;
		.MuiDialogContent-root:first-child {
			padding: 0;
		}
	}
	.MuiDialog-paperWidthSm {
		max-width: 100%;
	}
}

.common-scroll-wrapper {
	overflow-y: auto;
	overflow-x: hidden;
	//border-bottom: 1px solid #979797;
	&::-webkit-scrollbar {
		width: 14px;
		height: 14px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #bebebe;
		border-radius: 12px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
.table-scroll-wrapper {
	// position: absolute;
	max-height: 60vh;
	overflow-y: auto;
	//border-bottom: 1px solid #979797;
	&::-webkit-scrollbar {
		width: 14px;
		height: 14px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #bebebe;
		border-radius: 12px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	table {
		border-top: 0;
	}

	thead {
		position: sticky;
		left: 0;
		top: 0;
		z-index: 100;
	}

	th {
		position: sticky;
		left: 0;
		top: 0;
		border-right: 0;
	}
	th::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		border-right: 1px solid #979797;
	}
	th:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		border-bottom: 1px solid #979797;
	}
}

.table-scroll-wrapper.remove-border {
	th::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		border-right: 1px solid #ffffff;
	}
	th:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		border-bottom: 1px solid #ffffff;
	}
}
div[role="tooltip"] {
	z-index: 1000;
}

.task-expand-table {
	thead {
		position: relative;
	}

	th {
		position: relative;
	}
}
.MuiDialog-root {
	position: fixed !important;
}

.dynamic-width-table {
	// position: relative;
	max-width: 100vw;

	@media only screen and (min-width: 0px) and (max-width: 1024px) {
		max-width: 84vw;
	}
	@media only screen and (min-width: 1025px) and (max-width: 1600px) {
		max-width: 91vw;
	}
	@media only screen and (min-width: 1601px) and (max-width: 2400px) {
		max-width: 94vw;
	}
	@media only screen and (min-width: 2400px) and (max-width: 2800px) {
		max-width: 96vw;
	}
	@media only screen and (min-width: 2800px) {
		max-width: 95vw;
	}
}

div[role="progressbar"][aria-valuetext="1%"]:not([aria-valuetext="0%"]):not([aria-valuetext="Complete"])
	> div
	> div {
	min-width: 3px;
}
div[role="progressbar"][aria-valuetext="2%"]:not([aria-valuetext="0%"]):not([aria-valuetext="Complete"])
	> div
	> div {
	min-width: 3px;
}
div[role="progressbar"][aria-valuetext="3%"]:not([aria-valuetext="0%"]):not([aria-valuetext="Complete"])
	> div
	> div {
	min-width: 3.3px;
}
div[role="progressbar"][aria-valuetext="4%"]:not([aria-valuetext="0%"]):not([aria-valuetext="Complete"])
	> div
	> div {
	min-width: 3.8px;
}
div[role="progressbar"][aria-valuetext="5%"]:not([aria-valuetext="0%"]):not([aria-valuetext="Complete"])
	> div
	> div {
	min-width: 4px;
}

.MuiAccordion-rounded {
	border-radius: 4px !important;
}

.content-dialog .MuiDialog-paper {
	max-width: 1000px;
}
