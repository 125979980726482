$border-color: #c1c1c1;
$dropdown-fontcolor: #404149;
$dropdown-errorcolor: #f44336;
.dropdown,
.autocompletebox {
	position: relative;
	color: $dropdown-fontcolor;
	font-size: 1rem;
	.arrow-down {
		transform: scale(0.8);
	}
	.label {
		font-family: Roboto Condensed;
		font-weight: bold;
		font-size: 14px;
		color: $dropdown-fontcolor;
		margin-bottom: 0.35em;
	}

	.dropbox {
		label {
			margin-bottom: 10px;
			display: block;
		}
		.inputbox {
			padding: 15.5px 14px;
			border: 0.9px solid #b9b9b9;
			border-radius: 3px;
			cursor: pointer;
			&:hover {
				background: #f5f5f5;
			}
		}
		.inputbox.integrations {
			padding: 14.5px 14px;
			border: 0.9px solid #b9b9b9;
			background-color: "red";
			border-radius: 3px;
			cursor: pointer;
			&:hover {
				background: #f5f5f5;
			}
		}
		.error {
			border: 1.5px solid $dropdown-errorcolor !important;
		}
		.icon-box {
			padding: 12px 14px;
		}
	}
	&-expand {
		width: 500px;
		position: fixed;
		//left: 17px;
		background: #fff;
		box-shadow: 1px 1px 5px #7d7d7d;
		border-radius: 5px;
		z-index: 1000;
		.search-box {
			margin: 15px;

			&__text {
				padding: 8px;
				border: 0;
				flex: 1 1;
				outline: none;
			}
			.input-field {
				border-bottom: 2px solid $border-color;
				width: 90%;
			}
		}

		.dropdown-content,
		.autocompletebox-content {
			width: 100%;
			margin-bottom: 5px;
			// max-height: 40vh;
			// overflow: auto;
		}

		.dynamic-drop-list {
			display: grid;
			width: 100%;

			//grid-gap: 10px;
			// height: 243px;
			// padding-bottom: 10px;
			max-height: 32vh;
			border-top: 1px solid $border-color;
			overflow: auto;
			&::-webkit-scrollbar {
				width: 14px;
				height: 14px;
			}

			.ForwardRef-root-11 {
				transform: scale(0.7) rotate(180deg);
				margin-left: auto;
			}
			.ForwardRef-root-12 {
				transform: scale(0.7) rotate(360deg);
				margin-left: auto;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #bebebe;
				border-radius: 12px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}

			.checklist-item {
				padding: 15px;
				border-bottom: 1px solid $border-color;
			}

			.list-item {
				padding: 15px 0;
				cursor: pointer;
				border-bottom: 1px solid $border-color;
				&:hover {
					background: #307ad6;
					color: #fff;
				}
				.check {
					font-size: 28px;
					display: none;
				}
			}
			.hoverOver {
				background: #307ad6;
				color: #fff;
			}
			.no-record {
				padding: 15px;
			}
			.selected {
				background: #307ad6;
				padding: 10px 3px;
				color: #fff;
				.check {
					display: block;
				}
			}
		}
		.drop-list {
			display: grid;
			//grid-gap: 10px;
			// padding-bottom: 10px;
			margin-bottom: 5px;

			max-height: 42vh;
			border-top: 1px solid $border-color;
			overflow: auto;
			/* width */
			&::-webkit-scrollbar {
				width: 7px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #bebebe;
				border-radius: 12px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}

			.list-item {
				padding: 15px;
				cursor: pointer;
				border-bottom: 1px solid $border-color;
				&:hover {
					background: #307ad6;
					color: #fff;
					//padding: 10px 15px;
					// .check {
					// 	display: block;
					// }
				}
				.check {
					font-size: 28px;
					display: none;
				}
			}
			.no-record {
				padding: 15px;
			}
			.selected {
				background: #307ad6;
				padding: 10px 15px;
				color: #fff;
				.check {
					display: block;
				}
			}
			.hoverOver {
				background: #307ad6;
				color: #fff;
			}
		}
		// &.upward {
		// 	top: -15px;
		// }
		// &.downward {
		// 	bottom: 15px;
		// }
		// &.rightSide {
		// 	left: -30px;
		// }
		// &.leftSide {
		// 	right: 30px;
		// }

		@media screen and (max-width: 414px) {
			width: 330px;
		}
		@media screen and (max-width: 376px) {
			width: 300px;
		}
		display: none;
		&.active {
			display: block;
		}
	}
}
