.mobileViewGrid {
	display: none !important;
}

.mobileViewGridWithDiffDisplay {
	display: none !important;
}

.actionSpinnerBtn {
	margin-right: 10;
	width: 150px;
	background-color: "transparent";
}

.duplicateTitle {
	display: inline-flex;
}

.duplicateDialogContent {
	width: 500px;
}

.duplicateInputContainer {
	width: 100%;
	display: "flex";
	margin-bottom: 20;
}

@media only screen and (max-width: 840px) {
	.applicationNav {
		flex-direction: column;
		align-items: flex-start;
	}

	.actionButtonContainer {
		margin-left: 0 !important;
	}
}

@media only screen and (max-width: 414px) {
	.applicationContentContainer {
		margin-bottom: 50px;
	}

	.actionButtonContainer {
		width: 100% !important;
		display: flex;
		justify-content: center;
	}

	.actionButtonsBtn {
		width: 100px !important;
	}

	.actionSpinnerBtn {
		width: 100px;
	}

	.buttonGroup {
		max-width: 84vw;
		overflow: auto;
		margin: 0 auto;
	}

	.largeBtn {
		min-width: 170px !important;
		font-size: 12px !important;
	}

	.desktopViewGrid {
		display: none !important;
	}

	.mobileViewGrid {
		display: flex !important;
	}

	.mobileViewGridWithDiffDisplay {
		display: block !important;
	}

	.ticketBox {
		margin-left: 0% !important;
	}

	.colorContainer {
		padding-right: 0% !important;
	}

	.logoContainer {
		padding-left: 0% !important;
	}

	.logoContainerRight {
		padding-right: 0% !important;
	}

	.otherAssets {
		padding-left: 0% !important;
	}

	.duplicateTitle {
		display: inline;
		flex-wrap: wrap;
	}

	.duplicateDialogContent {
		width: 100%;
		/* background-color: red; */
	}

	.duplicateInputContainer {
		width: 100%;
		overflow: hidden;
	}
}
