.ql-toolbar.ql-snow {
	border: 1px solid #ccc;
	box-sizing: border-box;
	font-family: "Roboto Condensed";
	padding: 8px;
	border-radius: 5px 5px 0 0;
}

.ql-container.ql-snow {
	border: 1px solid #ccc;
	border-radius: 0 0 5px 5px;
	font-family: "Roboto Condensed";
	font-size: 14px;
}
